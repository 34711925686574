import App from './App';
import { PublicClientApplication, EventType, InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { tenantId, webClientId } from "./utils/config";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

const msalConfig = {
	auth: {
		clientId: webClientId,
		authority: "https://login.microsoftonline.com/" + tenantId,
		redirectUri: "/",
		postLogoutRedirectUri: "/"
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: isIE || isEdge || isFirefox
	}
};

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	const allAccounts = msalInstance.getAllAccounts();
	let accountInTenant = null;
	allAccounts.forEach(a=>{
		if(a.tenantId === tenantId){
			accountInTenant = a;
		}
	})
	msalInstance.setActiveAccount(accountInTenant);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();
msalInstance.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
		const account = event.payload.account;
		msalInstance.setActiveAccount(account);
	}
});

export default function AppWithMSAL() {
	return (
		<MsalProvider instance={msalInstance}>
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} errorComponent={<div>There's been an error signing you in!</div>} >
				<App />
			</MsalAuthenticationTemplate>
		</MsalProvider>
	);
}