export function formatFileSize(bytes){
	const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let u = 0
	while(bytes >= 1024 && u < units.length - 1){
		bytes/=1024
		u++;
	}

	let dp = Math.floor(bytes) === bytes ? 0 : bytes.toString().split(".")[1].length || 0;
	if(dp>3){dp=3}

	return bytes.toFixed(dp) + ' ' + units[u];
}