import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { BlobServiceClient } from "@azure/storage-blob";
import { callApi } from '../utils/api';
import { formatFileSize } from '../utils/utils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';

export default function FileUpload({ listFiles, className }) {
	const msal = useMsal();
	const [selectedFile, setSelectedFile] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [uploadPercent, setUploadPercent] = useState(0);

	const upload = e => {
		e.preventDefault();
		setUploading(true);
		callApi(msal, "POST", selectedFile.name, selectedFile.size).then(res => {
			const blobServiceClient = new BlobServiceClient(res.url);
			const containerClient = blobServiceClient.getContainerClient(res.container);
			const blockBlobClient = containerClient.getBlockBlobClient(res.blob);
			blockBlobClient.upload(selectedFile, selectedFile.size, {onProgress: progress => {setUploadPercent(progress.loadedBytes/selectedFile.size*100)}}).then(fileRes => {
				setTimeout(()=>{
					setSelectedFile(null);
					setUploading(false);
					setUploadPercent(0);
					document.getElementById("fileInput").value = "";
					listFiles();
				}, 1500)
			})
		}).catch(err => {
			console.log(err.message);
		});
	}

	return (
		<Row className={className}>
			<Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}  className="px-5 py-4 bg-primary bg-opacity-10 rounded-3">
				<Form className="d-flex flex-column align-items-center">

					<h1 className="mb-3">Upload a file</h1>
					<div className="custom-file-input d-flex align-items-center w-100 mb-2">
						<label htmlFor="fileInput" className={(!selectedFile || selectedFile?.size > 4194304000 || uploading) ? "btn btn-primary" : "btn btn-light"}>
							Choose File
						</label>
						<input type="file" id="fileInput" data-id="fileInput" onChange={e => setSelectedFile(e.target.files[0])} />
						<div className="flex-grow-1 border border-light rounded-end bg-white">{selectedFile ? selectedFile.name : "No file chosen"}</div>
					</div>
					<div className="text-muted">{selectedFile && `Size: ${formatFileSize(selectedFile.size)} Last Modified: ${(new Date(selectedFile.lastModified)).toLocaleString('en-GB')}`}</div>
					
					
					{/* <Form.Group className="d-flex flex-column align-items-center w-100">
						<Form.Label className="display-4 mb-3" htmlFor="fileInput">Upload a file</Form.Label>
						<Form.Control className="mb-2" type="file" id="fileInput" onChange={e => setSelectedFile(e.target.files[0])} />
						<Form.Text className="text-muted">{selectedFile && `Size: ${formatFileSize(selectedFile.size)} Last Modified: ${(new Date(selectedFile.lastModified)).toLocaleString('en-GB')}`}</Form.Text>
					</Form.Group> */}


					<Button variant={(!selectedFile || selectedFile?.size > 4194304000 || uploading) ? "light" : "primary"} type="submit" className="my-2 w-100" onClick={e => upload(e)} disabled={!selectedFile || selectedFile?.size > 4194304000 || uploading}>
						{uploading ? 
							<>
								<Spinner as="span" animation="border" size="sm"	role="status" aria-hidden="true" />
								<span className="visually-hidden">Loading...</span>
							</>
						: selectedFile?.size > 4194304000 ?
							"File Too Large"
						:
							"Upload"
						}
					</Button>
					{uploading &&
						<ProgressBar className="w-100 mt-2" animated now={uploadPercent} label={uploadPercent.toFixed() + "%"} />
					}					
				</Form>
			</Col>
		</Row>
	);
}