import React from 'react';
import AppWithMSAL from './AppWithMSAL';
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<AppWithMSAL />
		</BrowserRouter>
	</React.StrictMode>
);