import Row from 'react-bootstrap/Row';
import { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { syopsVersion, syopsContent } from '../syops-content/syops-content';

export default function Syops({ className, syopsAccepted, setSyopsAccepted }) {
	useEffect(() => {
		const daysToRevalidateSyops = 180;
		const msToRevalidateSyops = 1000 * 60 * 60 * 24 * daysToRevalidateSyops;
		if (syopsAccepted && syopsAccepted < Date.now() - msToRevalidateSyops) {
			setSyopsAccepted(null);
			localStorage.removeItem("syopsAccepted-" + syopsVersion);
			document.querySelector("#syopsHeader > button").click();
		}
	}, [syopsAccepted, setSyopsAccepted])

	const acceptSyops = () => {
		document.querySelector("#syopsHeader > button").click();
		const msNow = Date.now()
		localStorage.setItem("syopsAccepted-" + syopsVersion, msNow);
		setSyopsAccepted(msNow);
	}

	return (
		<Row className={className}>
			<Col>
				<Accordion defaultActiveKey={syopsAccepted ? null : "0"}>
					<Accordion.Item eventKey="0">
						<Accordion.Header as="h1" id="syopsHeader">SyOps v{syopsVersion} {syopsAccepted ? `(last accepted -  ${(new Date(Number(syopsAccepted))).toLocaleDateString("en-GB")})` : ""}</Accordion.Header>
						<Accordion.Body>
							{syopsContent}
							<Button variant="primary" onClick={acceptSyops}>Accept</Button>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Col>
		</Row>
	);
}