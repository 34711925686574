export const syopsVersion = "1.0";

export const syopsContent = <>
	<h2>CRYSTAL BAY File Transfer Tool</h2>
	<p>This application provides a secure and controlled method of transferring files between corporate and CRYSTAL BAY domains.</p>
	<p>You are responsible for ensuring any operations you perform using this tool are appropriate and that your use is compliant with the CRYSTAL BAY security operating procedures (SyOPs).</p>
	<p>Use of this tool is monitored and inappropriate use may lead to disciplinary action.</p>
	<p>If you have any questions or concerns about the use of this tool or any specific file transfer please contact your account security contact before uploading any documents</p>
	<h3>Do:</h3>
	<ul>
		<li>Log in, upload and download files using devices in the corporate and CRYSTAL BAY domains</li>
		<li>Use this tool to transfer appropriately sanitised files between corporate and CRYSTAL BAY domains and vice versa</li>
		<li>Ensure all files have been checked for malware or other known vulnerabilities before uploading them</li>
	</ul>
	<h3>Do Not:</h3>
	<ul>
		<li>Log in using any device that is not compliant with the TREADMILL and CRYSTAL BAY SyOPs</li>
		<li>Upload or download files for any purpose other than their transfer between corporate and CRYSTAL BAY domains</li>
		<li>Upload any files that include personal information that could identify any individual in content or metadata</li>
		<li>Upload any files that include any identities used in the CRYSTAL BAY domain, or any domain names associated with CRYSTAL BAY or other onward domains</li>
		<li>Transfer any intellectual property out of the CRYSTAL BAY domain without approval from the owner</li>
		<li>Transfer files between corporate and CRYSTAL BAY domains by any mechanism other than this tool without written approval from your account security contact</li>
		<li>Download or otherwise interact with any files you do not recognise as having uploaded yourself</li>
	</ul>
</>