import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { callApi } from '../utils/api';
import { formatFileSize } from '../utils/utils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner';

export default function FileList({ files, className }) {
	const msal = useMsal();
	const [order, setOrder] = useState("Upload Date (newest first)");
	const [gettingFiles, setGettingFiles] = useState([]);

	const getFile = (fileName, fileSize) => {
		if (gettingFiles.indexOf(fileName) < 0) {
			setGettingFiles([...gettingFiles, fileName])
			callApi(msal, "GET", fileName, fileSize).then(res => {
				window.location = res.url;
				setGettingFiles(gettingFiles.filter(f => f !== fileName));
			}).catch(err => {
				console.log(err.message);
			});
		}
	}

	const sortFiles = option => {
		switch (option) {
			case "Upload Date (newest first)":
				return (a, b) => new Date(b.properties.lastModified) - new Date(a.properties.lastModified)
			case "Upload Date (oldest first)":
				return (a, b) => new Date(a.properties.lastModified) - new Date(b.properties.lastModified)
			case "Alphabetical (A-Z)":
				return (a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0
			case "Alphabetical (Z-A)":
				return (a, b) => b.name.toUpperCase() < a.name.toUpperCase() ? -1 : b.name.toUpperCase() > a.name.toUpperCase() ? 1 : 0
			case "Size (largest first)":
				return (a, b) => b.properties.contentLength - a.properties.contentLength
			case "Size (smallest first)":
				return (a, b) => a.properties.contentLength - b.properties.contentLength
			default:
			// All cases handled
		}
	}

	return (
		<Row className={className}>
			<Col xs={12}>
				<h1>Recent Files</h1>
			</Col>
			<Col xs={12}>
				<Dropdown align="end" className="d-flex">
					<Dropdown.Toggle className="flex-grow-1 flex-md-grow-0">Ordered By {order}</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item onClick={() => { setOrder("Upload Date (newest first)") }}>Upload Date (newest first)</Dropdown.Item>
						<Dropdown.Item onClick={() => { setOrder("Upload Date (oldest first)") }}>Upload Date (oldest first)</Dropdown.Item>
						<Dropdown.Item onClick={() => { setOrder("Alphabetical (A-Z)") }}>Alphabetical (A-Z)</Dropdown.Item>
						<Dropdown.Item onClick={() => { setOrder("Alphabetical (Z-A)") }}>Alphabetical (Z-A)</Dropdown.Item>
						<Dropdown.Item onClick={() => { setOrder("Size (largest first)") }}>Size (largest first)</Dropdown.Item>
						<Dropdown.Item onClick={() => { setOrder("Size (smallest first)") }}>Size (smallest first)</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Col>
			{!files ?
				<Col className="py-4">
					<Spinner animation="border" role="status" aria-hidden="true" className="position-relative top-50 start-50 custom-spinner" />
				</Col>
			: files.length === 0 ?
				<Col>
					Nothing to show here...
				</Col>
			: files.sort(sortFiles(order)).map(f => (
				<Col key={f.name} xs={6} md={4} xl={3} className="mb-3">
					<Card onClick={() => getFile(encodeURIComponent(f.name), f.properties.contentLength)} className={"my-2 h-100" + (gettingFiles.indexOf(f.name) < 0 ? " file" : "")}>
						{gettingFiles.indexOf(f.name) >= 0 && 
							<Spinner animation="border" role="status" aria-hidden="true" className="position-absolute top-50 start-50 custom-spinner" />
						}
						<Card.Body className={"d-flex flex-column user-select-none" + (gettingFiles.indexOf(f.name) >= 0 ? " opacity-25" : "")}>
							<Card.Title className="flex-grow-1">{f.name}</Card.Title>
							<Card.Text className="mb-0">{`Size: ${formatFileSize(f.properties.contentLength)}`}</Card.Text>
							<Card.Text className="mb-0">{`Date: ${(new Date(f.properties.lastModified)).toLocaleDateString('en-GB')}`}</Card.Text>
							<Card.Text className="mb-0">{`Time: ${(new Date(f.properties.lastModified)).toLocaleTimeString('en-GB')}`}</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			))}
		</Row>
	);
}