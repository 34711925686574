import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import Nav from 'react-bootstrap/Nav'

export default function SignInSignOutButton({className}) {
    const { instance  } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return <Nav.Link className={className} onClick={() => instance.logoutRedirect()}>Sign Out</Nav.Link>;
    } else if (instance  !== InteractionStatus.Startup && instance  !== InteractionStatus.HandleRedirect) {
        // instance check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
        return <Nav.Link className={className} onClick={() => instance.loginRedirect({scopes: ["User.Read"]})}>Sign In</Nav.Link>;
    } else {
        return null;
    }
}