import { useMsal } from "@azure/msal-react";
import { Routes, Route } from "react-router-dom";
import './App.scss';

import SignInSignOutButton from './components/SignInSignOutButton';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Home from './pages/Home';

export default function App() {

	const { instance } = useMsal();

	return (
		<>
			<Navbar bg="primary" variant="dark" expand="lg">
				<Container>
					<Navbar.Brand className="flex-grow-1">Data Transfer</Navbar.Brand>
					<Nav>
						<Nav.Link disabled className="text-white">{instance.getActiveAccount().name} ({instance.getActiveAccount().username})</Nav.Link>
						<SignInSignOutButton className="text-white" />
					</Nav>
				</Container>
			</Navbar>
			<Routes>
				<Route path="/" element={<Home />} />
			</Routes>
		</>
	);
}
