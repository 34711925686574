import { useState, useEffect, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { callApi } from '../utils/api';
import Container from 'react-bootstrap/Container';
import Syops from '../components/Syops';
import FileUpload from '../components/FileUpload';
import FileList from '../components/FileList';
import { syopsVersion } from '../syops-content/syops-content';

export default function Home() {
	const msal = useMsal();
	const [files, setFiles] = useState(null);
	const [syopsAccepted, setSyopsAccepted] = useState(localStorage.getItem("syopsAccepted-" + syopsVersion));

	const listFiles = useCallback(() => {
		callApi(msal, "GET").then(res => {
			setFiles(res.files);
		}).catch(err => {
			console.log(err.message);
		});
	}, [msal]);

	useEffect(() => {
		listFiles();
	}, [msal, listFiles]);

	return (
		<Container className="mb-5">
			<Syops className="my-5" syopsAccepted={syopsAccepted} setSyopsAccepted={setSyopsAccepted} syopsVersion={syopsVersion} />
			{syopsAccepted &&
				<>
					<FileUpload className="my-5" listFiles={listFiles} />
					<FileList className="my-5" files={files} />
				</>
			}
		</Container>
	);
}
