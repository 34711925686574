import { apiRedirectUrl, apiUrl, apiScope } from "./config";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";

export const callApi = async ({ instance, inProgress, accounts }, method, fileName, fileSize) => {
	if (inProgress === InteractionStatus.None) {
		// Get access token for AAD
		var request = {
			scopes: [apiScope],
			account: accounts[0]
		};
		let aadToken = null;
		try {
			aadToken = await instance.acquireTokenSilent(request);
		} catch (error) {
			if (error instanceof InteractionRequiredAuthError) {
				// fallback to interaction when silent call fails
				return instance.acquireTokenRedirect(request)
			} else {
				throw error;
			}
		}

		// Now exchange it for a token for the API
		var options = {
			method: "POST",
			body: JSON.stringify({
				access_token: aadToken.accessToken,
				id_token: aadToken.idToken
			})
		};

		let apiTokenRes = await fetch(apiRedirectUrl, options);
		let apiToken = await apiTokenRes.json();

		// Now we can call the API with this token
		var headers = new Headers();
		headers.append("X-ZUMO-AUTH", apiToken.authenticationToken);
		options = {
			method: method,
			headers: headers
		};

		let url = apiUrl;
		if (method === "GET" && fileName) { url += "?fileName=" + fileName + "&fileSize=" + fileSize}
		if (method === "POST" && fileName) { options.body = JSON.stringify({ fileName: fileName, fileSize: fileSize }) }

		let apiRes = await fetch(url, options);
		if (!apiRes.ok) {
			throw new Error(await apiRes.text());
		}
		return await apiRes.json();
	} else {
		return new Promise((resolve, reject) => {
			reject("msal is busy");
		})
	}
}
